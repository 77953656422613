/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Phone } from 'emotion-icons/material'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { Form } from '@leshen/ui'

import {
  Accordion,
  Billboard,
  Columns,
  Image,
  ItemBank,
  Link,
  LinkButton,
  List,
  ListItem,
  PackageCard,
  SplitContent,
  Stack,
  SVGImage,
  Typography,
  useMapi,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'

import { graphql } from 'gatsby'
import getFullStateName from '../utils/getFullStateName'

const { EmilyClient } = require('@clearlink/emily')

const StateTemplate = ({ data }) => {
  const { Meta_Description, State, Page_Path, Meta_Title, Promo_Code } =
    data.pageInfo.edges[0].node

  const { requestId } = useMapi()
  const State_Name = getFullStateName(State)

  const brandyPackageList = [
    'viv-sigil-starter',
    'viv-sigil-premium',
    'viv-sigil-premiumplus',
  ]

  const packageList = brandyPackageList
    .map((packageId) =>
      data?.allContentfulPackage?.edges?.find(
        ({ node }) => node.identifier === packageId
      )
    )
    .filter((node) => node)

  const { rotatedNumber } = useMapi()

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: Page_Path,
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: 'vivintsource',
      },
      pagePath: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }
  const defaultPromoCode = passedData.contentfulPage.promoCode

  const indoorCameraProData = {
    ...data.indoorCameraPro?.cloudinary[0]?.gatsbyImageData,
    context: data.indoorCameraPro?.cloudinary[0]?.context,
  }
  const indoorCameraProMobileData = {
    ...data.indoorCameraProMobile?.cloudinary[0]?.gatsbyImageData,
    context: data.indoorCameraProMobile?.cloudinary[0]?.context,
  }
  const doorAndWindowSensorData = {
    ...data.doorAndWindowSensor?.cloudinary[0]?.gatsbyImageData,
    context: data.doorAndWindowSensor?.cloudinary[0]?.context,
  }
  const doorAndWindowSensorMobileData = {
    ...data.doorAndWindowSensorMobile?.cloudinary[0]?.gatsbyImageData,
    context: data.doorAndWindowSensorMobile?.cloudinary[0]?.context,
  }
  const pressingSmartDoorbellData = {
    ...data.pressingSmartDoorbell?.cloudinary[0]?.gatsbyImageData,
    context: data.pressingSmartDoorbell?.cloudinary[0]?.context,
  }

  const allContentfulFormData = {
    ...data.allContentfulForm?.edges[0]?.node,
    emily_guid: data.allContentfulForm?.edges[0]?.node?.emily.emily_guid,
  }

  const allContentfulFormFields = {
    formFields: allContentfulFormData?.emily?.formFields,
  }

  // alter home owner field to include required and '*' in label
  allContentfulFormFields.formFields[16] = {
    element: 'input',
    label: '*Are you a homeowner?',
    name: 'home_owner',
    options: [
      { label: 'Yes', name: 'yes', value: 'Yes' },
      { label: 'No', name: 'no', value: 'No' },
    ],
    required: true,
    type: 'radio',
    value: null,
  }
  const allContentfulFormDisclaimer = {
    ...data.allContentfulForm?.edges[3]?.node?.formDisclaimer,
  }

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: `${current.node.Page_Path.toLowerCase()}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  // checks to see if city data is available for a given state
  const citiesAvailable = cityDropdownData && cityDropdownData.length > 0

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            variant="full"
            backgroundColor="dark"
            mainContent={
              <>
                <Typography variant="h1">
                  Vivint Home Security in {State_Name}
                </Typography>
                <Typography variant="h4">
                  Satisfy your home security needs with Vivint.
                </Typography>
                <LinkButton
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  variant="hero"
                  leftIcon={<Phone />}
                >
                  Call {rotatedNumber}
                </LinkButton>
              </>
            }
            image={<Image data={indoorCameraProData} isImageCritical />}
            mobileImage={
              <Image data={indoorCameraProMobileData} isImageCritical />
            }
          />

          <VariableContent
            alignMainContent="center"
            backgroundColor="secondary"
            mainContent={
              <>
                <SVGImage
                  svg={data.comprehensiveSystem.cloudinary[0].svg.code}
                  presentationWidth={
                    data.comprehensiveSystem.cloudinary[0].svg.presentationWidth
                  }
                />
                <br />
                <br />
                <Typography variant="h2">
                  Home security systems customized for your needs in{' '}
                  {State_Name}
                </Typography>
                <Typography>
                  Custom home security is simple with Vivint. Our products
                  collaborate seamlessly for a personalized home security setup.
                  From doors and windows to any overlooked areas, we&lsquo;ve
                  got them all covered.
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns>
                {packageList.map((currentPackage) => (
                  <PackageCard
                    label={currentPackage.node.label}
                    packageData={{ ...currentPackage.node.brandy }}
                    key={currentPackage.node.contentful_id}
                  />
                ))}
              </Columns>
            </Stack>
          </VariableContent>

          <SplitContent
            backgroundColor="dark"
            image={<Image data={doorAndWindowSensorData} />}
            mobileImage={<Image data={doorAndWindowSensorMobileData} />}
            mainContent={
              <>
                <Typography variant="h2">
                  Security sensors for all entry points
                </Typography>
                <Typography variant="h4">
                  Residents get security sensors for doors and windows.
                </Typography>
                <Typography variant="body">
                  Our security sensors safeguard your doors and windows to
                  deliver comprehensive protection against burglaries and
                  unwelcome intruders. If there&lsquo;s an unauthorized entry
                  attempt, an alarm will activate, promptly alerting our
                  vigilant monitoring team.
                </Typography>
              </>
            }
          />

          <SplitContent
            image={<Image data={pressingSmartDoorbellData} />}
            mainContent={
              <>
                <Typography variant="h2">
                  Smart Video Doorbell Cameras in {State_Name}
                </Typography>
                <Typography variant="body">
                  Keep your packages safe from theft by installing the Doorbell
                  Camera Pro. This smart video doorbell employs Smart Deter to
                  proactively shield your deliveries. Using computer vision chip
                  technology, the Doorbell Camera Pro intelligently identifies
                  both your packages and any suspicious individuals in the
                  vicinity.
                </Typography>
              </>
            }
          />

          <VariableContent
            alignMainContent="center"
            mainContent={
              <Typography variant="h2">Frequently Asked Questions</Typography>
            }
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion
                items={[
                  {
                    title: 'How much is Vivint?',
                    content: (
                      <>
                        <Typography variant="body">
                          The price of Vivint security services is dependent on
                          the package you select.
                        </Typography>
                        <Typography variant="body">
                          To learn more, call{' '}
                          <Link to={`tel:${rotatedNumber}`}>
                            {rotatedNumber}
                          </Link>{' '}
                          to speak with a customer service representative.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'What should I look for in a home security system?',
                    content: (
                      <>
                        <Typography variant="body">
                          <strong>
                            When evaluating a home security system, consider the
                            following key factors:
                          </strong>
                        </Typography>
                        <List>
                          <ListItem>
                            <Typography variant="body">
                              Comprehensive Coverage: Ensure the system can
                              cover all vulnerable entry points such as doors
                              and windows, and any blind spots in and around
                              your home.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              Monitoring Options: Choose between professional
                              monitoring services, self-monitoring via
                              smartphone apps, or a combination of both to suit
                              your needs and budget.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              Smart Integration: Look for systems that integrate
                              with other smart home devices like cameras,
                              doorbell cameras, and smart locks for enhanced
                              convenience and security.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              Reliability and Support: Prioritize systems with
                              reliable hardware and 24/7 customer support to
                              address any issues promptly and keep your home
                              secure.
                            </Typography>
                          </ListItem>
                        </List>
                      </>
                    ),
                  },
                  {
                    title: 'Are home security systems worth the money?',
                    content: (
                      <>
                        <Typography variant="body">
                          Yes. Adding a home security system can provide you
                          with peace of mind knowing that your home is secure.
                          Plus, they provide remote monitoring, offer protection
                          against burglary or intrusion, and may deter
                          criminals.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title:
                      'What is the Vivint Security Customer Service number?',
                    content: (
                      <>
                        <Typography variant="body">
                          The Vivint customer service number is{' '}
                          <Link to={`tel:${rotatedNumber}`}>
                            <Link to="tel:1-801-377-9111">1-801-377-9111</Link>
                          </Link>
                          .
                        </Typography>
                        <Typography variant="body">
                          Agents are available 7:00 a.m.–1:00 a.m. EST M-F to
                          assist with technical support and customer service
                          issues.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'Do you own your equipment from Vivint?',
                    content: (
                      <>
                        <Typography variant="body">
                          It’s possible to own your Vivint equipment. You may
                          either purchase your equipment up front or finance it
                          and pay the loan off over time.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'Are any cameras compatible with Vivint?',
                    content: (
                      <>
                        <Typography variant="body">
                          Only Vivint cameras are compatible with Vivint
                          equipment. Those cameras include:
                        </Typography>
                        <List>
                          <ListItem>
                            <Typography variant="body">
                              <Link to="/equipment/doorbell-camera">
                                <strong>Vivint Doorbell Camera Pro™</strong>
                              </Link>
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              <Link to="/equipment/outdoor-security-camera">
                                <strong>Vivint Outdoor Camera Pro</strong>
                              </Link>
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              <strong>Vivint Indoor Camera</strong>
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              <strong>Vivint Indoor Camera Pro</strong>
                            </Typography>
                          </ListItem>
                        </List>
                        <Typography variant="body">
                          See what other equipment is{' '}
                          <Link to="/equipment">
                            compatible with your Vivint security system
                          </Link>
                          .
                        </Typography>
                      </>
                    ),
                  },
                ]}
              />
            </Stack>
          </VariableContent>
          <VariableContent
            backgroundColor="light"
            mainContent={
              <>
                <Typography variant="h3">
                  Call&nbsp;
                  <Link
                    to={`tel:${rotatedNumber}`}
                    color="primary"
                    variant="hero"
                  >
                    {rotatedNumber}&nbsp;
                  </Link>
                  or complete the form for a free quote.
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Form
                formId={allContentfulFormData.emily_guid}
                formSource="eloqua"
                submitButtonText={allContentfulFormData.emily.submit.buttonText}
                submitButtonColor="primary"
                submitButtonOutlined={false}
                submitButtonGradient={false}
                inlineButton={false}
                formName={allContentfulFormData.emily.name}
                steps={null}
                fields={allContentfulFormFields.formFields}
                heading={null}
                isMultiStep={false}
                disclaimer={allContentfulFormDisclaimer}
                formDisclaimerPosition="Above Submit Button"
                onSubmit={async ({
                  values,
                  setShowSubmissionMessage,
                  setShowSubmissionErrorMessage,
                }) => {
                  const mergedValues = {
                    ...values,
                    brand: 'VIV',
                    promo_code: defaultPromoCode,
                    domain: 'https://www.vivintsource.com',
                    xxTrustedFormCertUrl:
                      'https://cert.trustedform.com/77f499b1133958cccf87f4dd53c3e8e341253b2b',
                    first_name: values.first_name,
                    last_name: values.last_name,
                    request_id: requestId,
                    email_address: values.email_address,
                    phone_number: values.phone_number,
                    home_owner: values.home_owner,
                  }
                  try {
                    const emilyClient = new EmilyClient(
                      'https://leads.clear.link'
                    )

                    const response = await emilyClient.submit(mergedValues)

                    if (response.status === 200) {
                      setShowSubmissionMessage(true) // Setting showSubmissionMessage to true here
                    } else {
                      setShowSubmissionMessage(false)
                    }
                    setShowSubmissionMessage(true)
                  } catch (error) {
                    // If an error occurs:
                    console.error('Submission error:', error)
                    setShowSubmissionMessage(false)
                    setShowSubmissionErrorMessage(true)
                  }
                }}
              />
            </Stack>
          </VariableContent>

          {citiesAvailable && (
            <VariableContent
              mainContent={
                <>
                  <Typography variant="h2">
                    Vivint Home Security in {State_Name} Cities
                  </Typography>
                </>
              }
              alignMainContent="Center"
              alignImageToBottom={false}
              centeredContent={false}
              backgroundColor={null}
              breadcrumbProps={null}
              customLink={null}
            >
              <ItemBank className="city-link-bank">
                {cityDropdownData?.map((current, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Link to={current.value} key={i}>
                    {current.label}
                  </Link>
                ))}
              </ItemBank>
            </VariableContent>
          )}
        </>
      }
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape('').isRequired,
}

export default StateTemplate

export const query = graphql`
  query StateTemplateQuery($pagePath: String, $stateAbbreviation: String) {
    site {
      ...LeshenMetadata
    }
    allContentfulForm(
      filter: { formGuid: { eq: "f98c7229-b013-4c4d-93f1-9d9e0731eef1" } }
    ) {
      edges {
        node {
          identifier
          heading
          subheading

          formDisclaimer {
            __typename
            ...LeshenDisclaimer
          }

          emily {
            emily_guid
            name
            submit {
              action
              buttonText
            }
            formFields {
              element
              label
              name
              type
              required
              value
              options {
                label
                name
                value
              }
            }
          }
        }
      }
    }
    pageInfo: allDatasetManagerVivintAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          City
          Page_Path
          Meta_Title
          Meta_Description
          Promo_Code
        }
      }
    }
    cities: allDatasetManagerVivintAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    indoorCameraPro: contentfulMedia(
      id: { eq: "7ab3bbbb-e57b-50d3-b000-4dde6def54f6" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    indoorCameraProMobile: contentfulMedia(
      id: { eq: "c7379817-526e-5f07-93f4-e92416d34c77" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    doorAndWindowSensor: contentfulMedia(
      id: { eq: "87173330-10a9-506f-a431-2c3ea9a4d507" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    doorAndWindowSensorMobile: contentfulMedia(
      id: { eq: "e1cd2885-833c-50c8-b8a9-d432b31c4205" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    pressingSmartDoorbell: contentfulMedia(
      id: { eq: "46920113-bed3-5b06-848c-6c8776ae1150" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    comprehensiveSystem: contentfulMedia(
      id: { eq: "da40ed68-53c8-5d33-8acb-3e9aadac67de" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    allContentfulPackage {
      edges {
        node {
          __typename # used to determine component to render
          id
          contentful_id # required to make association
          identifier # user created contentful identifier
          brandy {
            name
            details
            bullets {
              text
              disclaimer {
                text
                symbol
              }
            }
            price {
              currency
              sale
              amount
              duration
              prefix
              suffix
              before
              disclaimer {
                text
                symbol
              }
            }
          }
          label
        }
      }
    }
    allContentfulHeader(filter: { identifier: { eq: "SEO - English" } }) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(filter: { identifier: { eq: "SEO - English" } }) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
